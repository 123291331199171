@tailwind base;
@tailwind components;
@tailwind utilities;

@import "~normalize.css";
@import "~@blueprintjs/core/lib/css/blueprint.css";
@import "~@blueprintjs/icons/lib/css/blueprint-icons.css";
@import "~@blueprintjs/popover2/lib/css/blueprint-popover2.css";
@import "~@blueprintjs/datetime2/lib/css/blueprint-datetime2.css";
@import "~@blueprintjs/datetime/lib/css/blueprint-datetime.css";
@import "~@blueprintjs/select/lib/css/blueprint-select.css";

@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,400&display=swap");

* {
	font-family: "Roboto", sans-serif;
}

.toaster {
	position: fixed !important;
}

.react-tel-input .form-control {
	width: unset !important;
}
