.info-section__bottom-rectangle-cover {
  position: absolute;
  bottom: 0;
  right: 0;
  left: -50vw;
  height: 200px;
  z-index: 2;
  width: 0;
  height: 0;
  border-bottom: 35vw solid white;
  border-right: 150vw solid transparent;
}

.info-section__content {
  position: absolute;
  z-index: 10;
  left: 0;
  bottom: 0;
  top: 0;
  right: 0;
}

// .info-section__bottom-cover {
//   position: absolute;
//   border-radius: 20px;
//   top: -1550px;
//   right: 0;
//   left: 0;
//   z-index: -1;
//   height: 2000px;
//   width: 2000px;
//   background-color: #F8FAFC;
//   transform: rotate(12deg);
// }

// .info-section__bottom-cover:before {
//   content: '';
//   position: absolute;
//   top: 10%;
//   bottom: 10%;
//   right: -5%;
//   left: -5%;
//   background: inherit;
//   border-radius: 5% / 50%;
// }